import { GlobalRef } from "@sellernote/_shared/src/utils/common/GlobalRef";

import { TransType, TType } from "../types/common/i18n";

/**
 * 디자인시스템에서 사용하는 i18n Namespace만 사용함
 */
type I18NNamespaceListForDesignSystem =
  | "shared"
  | "common"
  | "common-new"
  | "components"
  | "sds-v1"
  | "sds-v2"
  | "sds-v3"
  | "utils";

type TTypeForDesignSystem = TType<I18NNamespaceListForDesignSystem>;

type I18nInstanceForDesignSystemType = {
  Trans: TransType;
  t: TTypeForDesignSystem;
};

/**
 * 디자인시스템에서 사용하는 i18n 인스턴스를 참조하는 변수 (싱글톤)
 */
const instanceRef = new GlobalRef<I18nInstanceForDesignSystemType>(
  "i18n.i18nInstanceForDesignSystem.instance"
);

/**
 * 디자인시스템에서 사용하는 i18n 인스턴스를 초기화함
 * - 어플리케이션에서 한 번 만 해주면 됨 (싱글톤으로 구현되어있어 중복 초기화헤도 문제는 없음)
 */
function initI18nInstanceForDesignSystem({
  Trans,
  t,
}: {
  Trans: TransType;
  t: TTypeForDesignSystem;
}): void {
  // 싱글톤이므로 기존 값이 있으면 리턴
  if (instanceRef.value) return;

  instanceRef.value = {
    t,
    Trans,
  };
}

/**
 * 디자인시스템에서 사용하는 i18n 인스턴스 객체를 반환
 * - 어플리케이션 레벨에서 `initI18nInstanceForDesignSystem`함수로 i18n을 초기화 해주므로 사용가능
 */
function getI18nInstanceForDesignSystem(): I18nInstanceForDesignSystemType {
  if (!instanceRef.value) {
    /**
     * i18n 인스턴스가 셋팅되지 않을때의 런타임 오류를 막기 위해 dummy 함수를 반환함
     */
    return {
      Trans: () => null,
      t: () => null,
    } as unknown as I18nInstanceForDesignSystemType;
  }

  return instanceRef.value;
}

const I18N_NAMESPACE_LIST_FOR_DESIGN_SYSTEM: I18NNamespaceListForDesignSystem[] =
  [
    "shared",
    "common",
    "common-new",
    "components",
    "utils",
    "sds-v1",
    "sds-v2",
    "sds-v3",
  ];

export {
  initI18nInstanceForDesignSystem,
  getI18nInstanceForDesignSystem,
  I18N_NAMESPACE_LIST_FOR_DESIGN_SYSTEM,
};
