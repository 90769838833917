import { Cluster } from "@googlemaps/markerclusterer";
import { Wrapper } from "@googlemaps/react-wrapper";

import { GOOGLE_MAP_COMMON_BOOT_OPTION } from "@sellernote/_shared/src/services/googleMap";

import Map from "./Map";

export interface Position {
  lat: number;
  lng: number;
}

export interface InfoWindow {
  content: string; // mark up 요소를 string 으로 변환하여 사용.
  type: "click" | "visible";
}

// TODO: 현재는 SVG 방식만 사용 다른 방법(HTML, CSS, URL) 등이 필요하면 추가
export type SvgTypeIconInfo = {
  type: "svg";
  svg: HTMLElement;
};

export interface MarkerInfo extends Position {
  label?: string;
  infoWindowData?: InfoWindow;
  iconInfo?: SvgTypeIconInfo;
  zIndex?: number;
  pixelOffset?: google.maps.Size;
}

export interface MarkerClustererInfo {
  label: string;
}

export default function GoogleMapWithCluster({
  height,
  paddingTop, // 지도를 비율에 맞춰 세로사이즈를 조정할 경우에 사용. height 값은 비우고 paddingTop 에만 퍼센테이지 값 string으로 전달. ex) "60%"
  borderRadius,
  zoom,
  centerPosition,
  markerInfoList,
  polylineInfo,
  setMarkerCluster,
  onGoogleApiLoaded,
  isAutoZoom,
  dashedPolylineInfo,
  hasError,
}: {
  height?: string;
  paddingTop?: string;
  borderRadius?: string;
  zoom?: number;
  centerPosition: Position;
  markerInfoList?: MarkerInfo[];
  polylineInfo?: google.maps.PolylineOptions;
  setMarkerCluster?: (cluster: Cluster) => MarkerClustererInfo;
  onGoogleApiLoaded?: (maps: { map: any }) => void;
  isAutoZoom?: boolean;
  dashedPolylineInfo?: google.maps.PolylineOptions;
  hasError?: boolean;
}) {
  return (
    <Wrapper {...GOOGLE_MAP_COMMON_BOOT_OPTION}>
      <Map
        center={centerPosition}
        zoom={zoom}
        streetViewControl={false}
        fullscreenControl={false}
        mapTypeControl={false}
        style={{
          flexGrow: "1",
          height: height ?? "auto",
          paddingTop: paddingTop ?? "0",
          borderRadius: borderRadius ?? "0",
        }}
        markerInfoList={markerInfoList}
        polylineInfo={polylineInfo}
        setMarkerCluster={setMarkerCluster}
        onGoogleApiLoaded={onGoogleApiLoaded}
        isAutoZoom={isAutoZoom}
        dashedPolylineInfo={dashedPolylineInfo}
        hasError={hasError}
      />
    </Wrapper>
  );
}
