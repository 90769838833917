import { useCallback, useEffect, useState } from "react";

/**
 * position과 테이블 헤더필터 컨텐츠의 width에 따라 테이블 헤더 필터의 스타일을 리턴한다.
 * position은 left, right, auto가 있음
 * auto로 설정한 경우 컨텐츠가 잘리는 경우 자동으로 잘리지 않는 방향으로 변경해준다.
 */
export default function useGetTableHeaderSelectPanelStyles({
  selectPanelElementRef,
  triggerPosition,
  windowScrollY,
  windowWidth,
  position,
  showsSelectPanel,
}: {
  selectPanelElementRef: React.RefObject<HTMLDivElement>;
  triggerPosition: DOMRect | null;
  windowScrollY: number;
  windowWidth: number;
  position?: "left" | "right" | "auto";
  showsSelectPanel: boolean;
}) {
  const [selectPanelStyles, setSelectPanelStyles] = useState<
    React.CSSProperties | undefined
  >(undefined);

  const calculatePosition = useCallback(() => {
    const panelElement = selectPanelElementRef.current;

    if (!panelElement || !triggerPosition) return undefined;

    const panelWidth = panelElement.clientWidth;

    const leftStyles = {
      top: (triggerPosition.top ?? 0) + 26 + windowScrollY,
      left: (triggerPosition.left ?? 0) - 8,
      opacity: 1,
    };

    const rightStyles = {
      top: (triggerPosition.top ?? 0) + 26 + windowScrollY,
      right: windowWidth - (triggerPosition.right ?? 0) - 24,
      opacity: 1,
    };

    if (position === "auto") {
      if (windowWidth - (triggerPosition.right ?? 0) < panelWidth) {
        return rightStyles;
      }
      return leftStyles;
    }

    if (position === "right") {
      return rightStyles;
    }

    return leftStyles;
  }, [
    selectPanelElementRef,
    triggerPosition,
    position,
    windowScrollY,
    windowWidth,
  ]);

  useEffect(() => {
    if (showsSelectPanel && !selectPanelStyles) {
      setSelectPanelStyles(calculatePosition());
    }
  }, [calculatePosition, selectPanelStyles, showsSelectPanel]);

  return { selectPanelStyles, setSelectPanelStyles };
}
