import { Cluster } from "@googlemaps/markerclusterer";

import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import Icon from "../../../../componentsToMoveToV1/Icon";
import { MarkerClustererInfo, MarkerInfo } from "..";
import useGoogleMap from "./useGoogleMap";
import Styled from "./index.styles";

export interface MapProps extends google.maps.MapOptions {
  markerInfoList?: MarkerInfo[];
  polylineInfo?: google.maps.PolylineOptions;
  dashedPolylineInfo?: google.maps.PolylineOptions;
  setMarkerCluster?: (cluster: Cluster) => MarkerClustererInfo;
  onGoogleApiLoaded?: (maps: { map: any }) => void;
  isAutoZoom?: boolean;
  hasError?: boolean;
}

export default function Map({
  markerInfoList,
  polylineInfo,
  dashedPolylineInfo,
  setMarkerCluster,
  onGoogleApiLoaded,
  style,
  isAutoZoom,
  hasError,
  ...options
}: MapProps & { style: { [key: string]: string } }) {
  const { ref } = useGoogleMap({
    markerInfoList,
    polylineInfo,
    dashedPolylineInfo,
    setMarkerCluster,
    onGoogleApiLoaded,
    isAutoZoom,
    hasError,
    ...options,
  });

  return (
    <Styled.container>
      {hasError && (
        <Styled.dimmedLayer>
          <Styled.message>
            <Icon type="warning" color={COLOR.wh} />

            <div className="text">지도 데이터 조회에 실패했습니다.</div>
          </Styled.message>
        </Styled.dimmedLayer>
      )}

      {/* 맵 생성 전 휠 스크롤 줌을 사용하는 상황에서 에러가 나와 조건 추가 */}
      {ref ? <div ref={ref} style={style} /> : null}
    </Styled.container>
  );
}
