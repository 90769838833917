import { useMemo } from "react";

import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import { isEmptyObjectOrArray } from "../../utils/common/etc";
import { findNearestIndex } from "../../utils/common/googleMap";

import { GET_BID_TRACKING_SHIP_RES } from "../../api-interfaces/shipda-api/bid";

function useShipmentMapPolyline({
  trackingShipResult,
}: {
  trackingShipResult: GET_BID_TRACKING_SHIP_RES | undefined;
}) {
  const polylineData = useMemo(() => {
    if (!trackingShipResult) return;

    if (isEmptyObjectOrArray(trackingShipResult.routes)) return;

    return trackingShipResult.routes.flatMap((routeArray) =>
      routeArray.map((port) => ({ lat: port.lat, lng: port.lng }))
    );
  }, [trackingShipResult]);

  // TODO: trackingShipResult가 없다면 AB13635 티켓에 따라 에러처리를 할 에정
  const splitIndex = useMemo(() => {
    if (!trackingShipResult) return;

    if (!polylineData) return;

    return findNearestIndex({
      pinLat: trackingShipResult?.pin.lat,
      pinLng: trackingShipResult?.pin.lng,
      routes: polylineData,
    });
  }, [polylineData, trackingShipResult]);

  const polylineInfo: google.maps.PolylineOptions | undefined = useMemo(() => {
    // splitIndex가 0일 수도 있음 데이터가 없다면 undefined 에러가 있는 경우 -1
    if (splitIndex === undefined || splitIndex === -1) return;

    if (!polylineData) return;

    return {
      path: polylineData.slice(0, splitIndex + 1),
      geodesic: false,
      strokeOpacity: 0.8,
      strokeColor: COLOR.grayScale_600,
      strokeWeight: 2,
    };
  }, [polylineData, splitIndex]);

  const dashedPolylineInfo = useMemo(() => {
    // splitIndex가 0일 수도 있음 데이터가 없다면 undefined 에러가 있는 경우 -1
    if (!splitIndex === undefined || splitIndex === -1) return;

    if (!polylineData) return;

    return {
      path: polylineData.slice(splitIndex),
      // 지구 곡률 반영
      geodesic: false,
      // 기본 선을 숨기기 위해 불투명도 설정
      strokeOpacity: 0,
      icons: [
        {
          icon: {
            //  2px 선
            path: "M 0,0 0,2",
            strokeOpacity: 0.8,
            scale: 1,
            strokeColor: COLOR.point_400,
            strokeWeight: 2,
          },
          offset: "0",
          /**
           * 피그마에서 SVG속성이 stroke-dasharray="2 4"로 구성되어있는데 이는 선이 2px고  + 4px 공백을 가짐
           * 6px는 선2px + 공백4px로 반복함을 의미함
           */
          repeat: "6px",
        },
      ],
    };
  }, [polylineData, splitIndex]);

  return { polylineInfo, dashedPolylineInfo };
}

export default useShipmentMapPolyline;
